.project-section {
  margin-top: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-wrapper {
  width: 90%;
}

.project-section-heading {
  text-align: left;
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  letter-spacing: -2px;
  margin-bottom: 80px;
  background-image: linear-gradient(to right, #000, #000);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 2px;
}

.projects {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .project-section-heading {
    font-size: 70px;
  }
  .projects-card {
    margin: 0 25px 60px 25px;
  }
}

@media screen and (max-width: 767px) {
  .project-section-heading {
    font-size: 50px;
  }
}

@media screen and (max-width: 479px) {
  .project-section-heading {
    font-size: 10vw;
  }
}
