.about-section {
  margin-top: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-wrapper {
  width: 80%;
}

.about-section-heading {
  text-align: center;
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  letter-spacing: -2px;
  margin-bottom: 80px;
}

.about-wrapper2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.about-left {
  flex: 1;
}

.about-img {
  width: 450px;
}

.about-right {
  flex: 1;
  margin-top: 20px;
  margin-left: -50px;
}

.about-short-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "Caveat";
  color: #bd1823;
}

.about-heading {
  font-size: 40px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  letter-spacing: -2px;
  margin-bottom: 30px;
  background-image: linear-gradient(to right, #bd1823, #bd1823);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 20% 2px;
}

.about-span {
  color: black;
  font-family: "Inter";
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 1.5;
}

.certifications {
  margin-top: 80px;
}

.about-heading2 {
  font-size: 40px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  margin-bottom: 30px;
  background-image: linear-gradient(to right, #bd1823, #bd1823);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 20% 2px;
}

.certificates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
}

.certificate-img {
  width: 580px;
  height: 380px;
  margin-bottom: 50px;
  border: 2px solid #bd1823;
}

.overlay {
  position: absolute;
  bottom: 55px;
  /* background: rgb(0, 0, 0); */
  background: rgba(0, 0, 0, 0.6); /* Black see-through */
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  font-family: "Inter";
  padding: 20px 0;
  text-align: center;
}

.image-container:hover .overlay {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .about-section-heading {
    font-size: 70px;
  }
  .about-img {
    width: 350px;
  }
  .about-short-heading {
    font-size: 20px;
  }
  .about-heading {
    font-size: 35px;
  }
  .about-span {
    font-size: 16px;
  }
  .certificates {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .about-section-heading {
    font-size: 50px;
  }
  .about-img {
    width: 250px;
  }
  .about-short-heading {
    font-size: 18px;
  }
  .about-heading {
    font-size: 35px;
  }
  .about-span {
    font-size: 15px;
  }
  .certificates {
    justify-content: center;
  }
}

@media screen and (max-width: 479px) {
  .about-section {
    margin-top: 100px;
    margin-bottom: -40px;
  }

  .about-section-heading {
    font-size: 10vw;
  }

  .about-wrapper2 {
    flex-direction: column;
  }

  .about-img {
    width: 360px;
  }

  .about-heading,
  .about-heading2 {
    font-size: 30px;
  }

  .about-span {
    font-size: 15px;
  }

  .about-right {
    margin-left: unset;
    margin-top: 65px;
  }

  .certificates {
    flex-direction: column;
  }

  .certificate-img {
    width: 330px;
    height: 220px;
  }
}

@media screen and (max-width: 380px) {
  .certificate-img {
    width: 288px;
    height: 200px;
  }
}
