html,
body,
* {
  /* cursor: none; */
}

html,
body {
  height: 0;
}
body {
  background: url(./Assets/line-background-1.svg);
  background-color: rgb(251, 250, 255);
  background-size: cover;
}

@media screen and (max-width: 991px) {
  body {
    background: url(./Assets/line-background-991.svg);
    background-color: rgb(251, 250, 255);
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  body {
    background: url(./Assets/line-background-767.svg);
    background-color: rgb(251, 250, 255);
    background-size: cover;
  }
}

@media screen and (max-width: 479px) {
  body {
    background: url(./Assets/line-background-479.svg);
    background-color: rgb(251, 250, 255);
    background-size: cover;
  }
}

.App {
  margin: 0;
  padding: 0;
}
.noise::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  content: "";
  /* opacity: 1; */
  opacity: 0.025;
  z-index: 1500;
  pointer-events: none;
  background: url(./Assets/noise.gif);
}
::-webkit-scrollbar {
  display: none;
}

.cursor-follower {
  position: absolute;
  background: url(./Assets/Red_asterisk.svg) no-repeat 50% 50%;
  background-size: cover;
  height: 50px;
  width: 50px;
  animation: animate-rotate 12s infinite linear;
  z-index: 1500;
  user-select: none;
  opacity: 0.8;
  mix-blend-mode: multiply;
  overflow: hidden;
  pointer-events: none;
  transform: translate(35px, 35px);
}
@keyframes animate-rotate {
  to {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .cursor-follower {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  iframe {
    bottom: 50px !important;
  }
}
