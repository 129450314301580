.resume {
  width: 100%;
}

.routing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.routing-main {
  width: 80%;
  display: flex;
  align-items: center;
  margin-top: 60px;
  justify-content: space-between;
}

.routing-links {
  margin-bottom: -30px;
  font-size: 20px;
  font-family: "MonumentExtended-Regular";
  font-weight: 400;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.routing-links:hover {
  color: brown;
}

.resume-link {
  text-decoration: "none";
  margin-top: "70px";
}

@media screen and (max-width: 479px) {
  .routing-main {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: -40px;
  }

  .resume-link {
    margin-bottom: 30px;
  }
}
