.my-skills-main-reel {
  width: 100%;
  overflow: hidden;
  margin-top: -140px;
}

.my-skills-main-reel p {
  text-align: center;
  color: #642420;
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}

.my-skills-reel {
  margin-top: 300px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  will-change: transform;
  animation: animation-reel 120s infinite linear;
}

@keyframes animation-reel {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.reel-item {
  font-weight: 400;
  font-family: "MonumentExtended-Regular";
  font-size: 80px;
  text-transform: uppercase;
}
/*  */

.skill-set-boxes {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 100px;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
}

.skill-set-box {
  margin: 50px 10px;
  display: flex;
  width: 400px;
  height: 180px;
  flex-direction: column;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='black' stroke-width='1' stroke-dasharray='16%2c 7%2c 18%2c 12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  /* border: 1px dashed #000;
    border-radius: 20px; */
  transition: color 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  transition: background-color 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: border 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}

.skill-set-box:hover {
  color: #fff;
  background-color: #101010;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='#ffffff' stroke-width='1' stroke-dasharray='16%2c 7%2c 18%2c 12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
}

.skill-set-box:hover .skill-set-box-p {
  color: #fff;
}

.skill-set-box:hover .skill-line {
  color: #fff;
}

.skill-set-box-main {
  display: flex;
  margin: 30px;
}

.skill-set-box-main-img {
  width: 40px;
}

.skill-set-box-h1 {
  font-size: 35px;
  font-family: "MonumentExtended-Regular";
  font-weight: 500;
  margin-left: 25px;
}

.skill-set-box-p {
  text-align: center;
  color: #642420;
  font-family: "Inter";
  font-weight: lighter;
  font-size: 15px;
  /* margin: 0 30px; */
}

.skill-line {
  margin: 0 18px;
  font-size: 16px;
  font-weight: 900;
  color: #000;
}

@media screen and (max-width: 991px) {
  .reel-item {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .reel-item {
    font-size: 50px;
  }
  .my-skills-main-reel p {
    font-size: 15px;
  }
  .skill-set-box-h1 {
    font-size: 25px;
  }
  .skill-set-box-p {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) {
  .reel-item {
    font-size: 12vw;
  }

  .my-skills-reel {
    margin-top: 230px;
  }

  .my-skills-main-reel p {
    font-size: 15px;
  }

  .skill-set-boxes {
    margin-top: 50px;
  }

  .skill-set-box {
    margin: 25px 10px;
    height: 150px;
  }

  .skill-set-box-h1 {
    font-size: 25px;
  }

  .skill-set-box-main-img {
    width: 30px;
  }
}
