.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.loader-container svg {
  width: 400px !important;
}

@media screen and (max-width: 479px) {
  .loader-container svg {
    width: 200px !important;
  }
}
