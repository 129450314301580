header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px auto;
  height: 70px;
  z-index: 1000;
  transition: transform 0.5s ease-in-out;
}

header.nav-bar--hidden {
  transform: translateY(-120%);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 5vw;
}

#logo img {
  width: 40px;
  cursor: pointer;
}

.button img {
  width: 40px;
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  transform: translateX(-100%);
}

.menu .button img {
  position: absolute;
  top: 50px;
  right: 100px;
  width: 25px;
  cursor: pointer;
  z-index: 10000;
}

.close {
  z-index: 1000;
  position: absolute;
  right: -20px;
}
.ul {
  position: absolute;
  left: 20%;
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: left;
  height: 100vh;
  flex-direction: column;
}
.ul li {
  margin: 20px 0;
  z-index: 2;
}
.ul li p {
  text-decoration: none;
  color: white;
  transition: 2s;
  font-family: "MonumentExtended-Regular";
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 500;
  mix-blend-mode: difference;
  cursor: pointer;
}
.social {
  position: absolute;
  bottom: 0;
  display: flex;
  height: 80px;
  width: 100%;
}
.social ul {
  margin-right: 80px;
  display: flex;
  list-style: none;
  position: absolute;
  right: 0;
}
.social ul li {
  margin: 0px 10px;
}
.social ul li img {
  cursor: pointer;
  width: 35px;
  height: 35px;
}
.bg {
  position: absolute;
  width: 250px;
  /* filter: grayscale(1); */
  filter: brightness(0.3);
  height: 300px;
  mix-blend-mode: difference;
}
.bg1 {
  top: 45px;
  left: 80px;
  background: url(../../Assets/gif1.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.bg2 {
  top: 100px;
  left: 150px;
  background: url(../../Assets/gif2.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.bg3 {
  top: 250px;
  left: 200px;
  background: url(../../Assets/gif3.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.bg4 {
  top: 400px;
  left: 200px;
  background: url(../../Assets/gif4.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.li1:hover ~ .bg1,
.li2:hover ~ .bg2,
.li3:hover ~ .bg3,
.li4:hover ~ .bg4 {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-in-out;
}
.ul li p:hover {
  transition: 2s;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
  font-style: italic;
}

@media screen and (max-width: 767px) {
  .ul li p {
    font-size: 35px;
  }
}

@media screen and (max-width: 479px) {
  #logo img {
    width: 30px;
  }
  .button img {
    width: 30px;
  }
  .ul {
    left: unset;
    bottom: 50px;
  }

  .ul li p {
    font-size: 30px;
  }

  .social {
    margin-bottom: 70px;
  }

  .social ul {
    right: unset;
    margin-right: unset;
  }
  .menu .button img {
    right: 55px;
    width: 20px;
  }
}
