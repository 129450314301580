.short-about {
  margin-top: 8vh;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.main-h1-short-about {
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-img-1 {
  width: 180px;
}

.bg-img-2 {
  width: 180px;
}

.sub-main-p-short-about {
  margin-top: 80px;
}

.short-about h1 {
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  margin-bottom: -30px;
  letter-spacing: -2px;
  text-align: center;
}
.short-about p {
  text-align: center;
  color: #642420;
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}

.know-more-resume {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
}
.know-more-about {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#know-more {
  padding-top: 30px;
  color: rgb(0, 133, 181) !important;
  cursor: pointer;
  font-family: "Inter";
}
.know-more-about img {
  width: 20px;
  /* transform: rotate(270deg); */
  color: rgb(0, 133, 181) !important;
  padding-top: 50px;
  padding-left: 20px;
  cursor: pointer;
}
.another-svg {
  margin-top: 50px;
}
.short-about .another-svg img {
  width: 50px;
  animation: animate-rotate 10s infinite linear;
}

@keyframes animate-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 991px) {
  .short-about h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .short-about h1 {
    font-size: 50px;
    margin-bottom: -18px;
  }

  .bg-img-1,
  .bg-img-2 {
    width: 130px;
  }

  .short-about p {
    font-size: 15px;
  }

  .about-btn a {
    font-size: 13px;
  }

  .short-about .another-svg img {
    width: 40px;
  }
}

@media screen and (max-width: 479px) {
  .short-about {
    /* margin-top: 10vh; */
  }

  .main-h1-short-about {
    margin-top: unset;
    justify-content: space-around;
  }

  .bg-img-1 {
    width: 80px;
    margin-left: unset;
  }

  .bg-img-2 {
    width: 80px;
    margin-right: unset;
  }

  .short-about h1 {
    font-size: 10vw;
    margin-bottom: -18px;
  }

  .sub-main-p-short-about {
    max-width: 300px;
  }

  .short-about p {
    font-size: 15px;
  }

  .know-more-resume {
    padding: unset;
    flex-direction: column;
  }

  .short-about .another-svg img {
    width: 40px;
  }
}

/*  */

.about-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}
.about-btn a {
  padding: 10px 30px;
  /* color: rgb(0, 133, 181) !important; */
  color: #000;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 10px;
  transition: all 0.3s;
}

.about-btn a span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-btn-svg {
  padding-left: 15px;
  transition: all 0.3s;
}

.about-btn a:hover {
  background: rgba(189, 24, 35, 0.1);
}

.about-btn a:hover .about-btn-svg {
  transform: translateX(8px);
}
