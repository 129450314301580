.contact-section {
  margin-top: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-wrapper {
  width: 80%;
}

.section-heading {
  text-align: center;
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  margin-bottom: -30px;
  letter-spacing: -2px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
  margin-top: 100px;
}

.contact-left {
  flex: 1;
}

.contact-heading {
  font-family: "Inter";
  color: black;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.contact-desc {
  color: black;
  font-family: "Inter";
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 2;
}

.contact-info {
  margin-bottom: 2rem;
}

.icon {
  color: black;
  font-size: 25px;
}

.text {
  color: black;
  font-size: 20px;
  font-family: "Inter";
  text-decoration: none;
}

.contact-info ul {
  list-style: none;
  padding-left: 0;
}

.contact-info ul li {
  border-radius: 12px;
  overflow: hidden;
}

.contact-info ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 2rem;
  padding: 1.5rem;
  text-decoration: none;
}

.social-info ul {
  display: flex;
  list-style: none;
  padding-inline-start: unset;
  gap: 2rem;
}

.brands {
  font-size: 2.5rem;
}

.social-info ul li a {
  color: black;
}

.contact-right {
  flex: 1;
}

.form-wrapper {
  background: rgba(249, 115, 22, 0.1);
  max-width: 500px;
  margin: 2px auto;
  padding: 5rem;
  border-radius: 12px;
  color: black;
  box-shadow: 0 0 0 1px #000, 4px 4px 0 #000;
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='black' stroke-width='1' stroke-dasharray='16%2c 7%2c 18%2c 12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e"); */
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: 0.5rem;
  display: inline-block;
  font-size: 20px;
  font-family: "Inter";
}

.input-wrapper {
  background-color: white;
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
}

.input-wrapper .icons {
  font-size: 20px;
  height: 100%;
  width: 50px;
  background-color: rgb(218, 218, 218);
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-wrapper input {
  padding: 1rem;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  font-size: 2rem;
}

.form-group textarea {
  width: 92%;
  height: 250px;
  resize: vertical;
  font-size: 2rem;
  padding: 1rem;
  outline: none;
  border: none;
  border-radius: 4px;
}

.form-wrapper button {
  background-image: linear-gradient(
    to right,
    #232526 0%,
    #414345 51%,
    #232526 100%
  );

  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  outline: none;
  border: none;
}

.form-wrapper button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .section-heading {
    font-size: 70px;
  }
  .wrapper {
    gap: 2rem;
  }
  .form-wrapper {
    padding: 2.5rem;
  }
}

@media screen and (max-width: 767px) {
  .section-heading {
    font-size: 50px;
  }
  .wrapper {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 479px) {
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-section {
    margin-top: 100px;
  }

  .wrapper {
    flex-direction: column-reverse;
  }

  .section-heading {
    font-size: 10vw;
    margin-bottom: -50px;
  }

  .form-wrapper {
    padding: 2rem;
  }

  .form-group textarea {
    width: 89%;
  }

  .contact-heading {
    font-size: 2rem;
  }

  .contact-desc {
    font-size: 16px;
    text-align: center;
  }

  .text {
    font-size: 16px;
    font-weight: 500;
  }

  .icon {
    font-size: 18px;
  }

  .social-info ul {
    gap: 1.2rem;
  }

  .contact-heading {
    text-align: center;
  }
}
