#HiThere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  pointer-events: none;
}

#HiThere h1 {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(30px * -1);
  white-space: nowrap;
  user-select: none;
  margin-top: -20px;
  font-family: "MonumentExtended-Regular";
  font-size: 23.3vw;
  font-weight: 400;
  color: rgba(147, 147, 147, 0.23);
  line-height: 26vw;
}

.main-wrapper {
  margin-top: 25vh;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main-heading-div {
  display: flex;
}

.main-heading {
  font-family: "MonumentExtended-Regular";
  font-size: 10vw;
  font-weight: 400;
  z-index: 10;
  background-image: linear-gradient(89.2deg, #f58342 0.08%, #f5326d 90.89%);
  background-clip: text;
  color: transparent;

  background-size: 300%;
  background-position: -100%;
  animation: textAnimate 3s infinite alternate-reverse;

  /* text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.1),
      0px -4px 10px rgba(255, 255, 255, 0.1); */
}

@keyframes textAnimate {
  from {
    background-position: 0%;
  }

  to {
    background-position: 100%;
  }
}

.main-heading::before {
  content: attr(data-outline);
  position: absolute;
  left: 2%;
  top: -20%;
  width: 100%;
  text-align: center;
  font-family: "MonumentExtended-Regular";
  letter-spacing: 0.2rem;
  color: transparent;
  /* -webkit-text-stroke: 1.5px rgba(0, 0, 0, 0.103); */
  -webkit-text-stroke: 1.5px #edeef71a;
  z-index: 0;
}

.main-heading:nth-child(1) {
  padding-right: 1.5vw;
}

.main-heading:nth-child(2) {
  padding-left: 1.5vw;
}

.top-main-heading {
  font-family: "Inter";
  font-size: 2.65vw;
  font-weight: 900;
  z-index: 10;
  margin-bottom: 40px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1.5px #000;
}

.bottom-main-heading {
  font-family: "Inter";
  height: 50px;
  font-size: 2.65vw;
  font-weight: 700;
  z-index: 10;
  margin-top: 40px;
}

.scroll-down {
  width: 180px;
  margin-top: 70px;
}

.flower {
  position: absolute;
  bottom: 100px;
  right: 100px;
  width: 80px;
  animation: animate-rotate 10s infinite linear;
}

@keyframes animate-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  */
@media screen and (max-width: 991px) {
  #HiThere h1 {
    top: unset;
  }
}

@media screen and (max-width: 767px) {
  #HiThere h1 {
    margin-top: 88px;
    font-size: 24vw;
    line-height: 30vw;
  }
  .main-wrapper {
    margin-top: 26vw;
  }
  .scroll-down {
    width: 130px;
    margin-top: 111px;
  }
}

@media screen and (max-width: 479px) {
  .main-wrapper {
    margin-top: 35vw;
  }
  .main-heading-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .main-heading:nth-child(1) {
    padding-right: unset;
  }
  .main-heading:nth-child(2) {
    padding-left: unset;
  }
  .main-heading {
    font-size: 18vw;
  }
  .top-main-heading {
    font-size: 6vw;
  }
  .bottom-main-heading {
    font-size: 5.5vw;
    margin-top: 65px;
  }
  #HiThere h1 {
    top: -15px;
    left: 20px;
    line-height: 60vw;
  }
  .scroll-down {
    width: 140px;
    margin-top: unset;
  }
  .flower {
    bottom: 125px;
    width: 40px;
  }
}
