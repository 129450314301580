@font-face {
  font-family: "MonumentExtended-Regular";
  src: local("MonumentExtended-Regular"),
    url(./Fonts/MonumentExtended-Regular.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "MonumentExtended-Ultrabold";
  src: local("MonumentExtended-Ultrabold"),
    url(./Fonts/MonumentExtended-Ultrabold.otf) format("opentype");
}

@font-face {
  font-family: "MonumentGrotesk-Bold";
  src: local("MonumentGrotesk-Bold"),
    url(./Fonts/MonumentGrotesk-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-BoldItalic";
  src: local("MonumentGrotesk-BoldItalic"),
    url(./Fonts/MonumentGrotesk-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Italic";
  src: local("MonumentGrotesk-Italic"),
    url(./Fonts/MonumentGrotesk-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Medium";
  src: local("MonumentGrotesk-Medium"),
    url(./Fonts/MonumentGrotesk-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-MediumItalic";
  src: local("MonumentGrotesk-MediumItalic"),
    url(./Fonts/MonumentGrotesk-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Mono";
  src: local("MonumentGrotesk-Mono"),
    url(./Fonts/MonumentGrotesk-Mono.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Regular";
  src: local("MonumentGrotesk-Regular"),
    url(./Fonts/MonumentGrotesk-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Semi-Mono";
  src: local("MonumentGrotesk-Semi-Mono"),
    url(./Fonts/MonumentGrotesk-Semi-Mono.ttf) format("truetype");
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"), url(./Fonts/Caveat.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@font-face {
  font-family: "Ogg";
  src: url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.eot");
  src: url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.svg#Ogg")
      format("svg");
}
