.contact-me {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.contact-me-wrapper {
  width: 90%;
}

.contact-me-title {
  font-size: 60px;
  font-family: "MonumentExtended-Regular";
  font-weight: 400;
}

.contact-me-subtitle {
  font-size: 40px;
  font-family: "MonumentGrotesk-Regular";
  font-weight: bold;
}

.footer-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 150px;
}

.footer-logo {
  width: 70px;
}

.footer-end-line {
  display: block;
  width: 500px;
  border-top: 1px solid black;
}

.email,
.phone {
  font-size: 20px;
  font-family: "MonumentGrotesk-Bold";
  text-decoration: none;
  color: black;
}

.email-link,
.phone-link {
  text-decoration: none;
}

.footer-end-left {
  height: 125px;
}

.footer-end-right {
  display: flex;
  height: 125px;
}

.footer-end-right img {
  width: 40px;
  height: 40px;
  margin: 30px 18px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .contact-me-title {
    font-size: 50px;
  }
  .contact-me-subtitle {
    font-size: 30px;
  }
  .footer-end-line {
    width: 333px;
  }
  .footer-end-right img {
    margin: 30px 8px;
  }
}

@media screen and (max-width: 767px) {
  .contact-me-title {
    font-size: 40px;
  }
  .contact-me-subtitle {
    font-size: 25px;
  }
  .footer-end {
    flex-direction: column;
    text-align: center;
  }
  .footer-logo {
    margin: 40px 0;
  }
  .footer-end-right {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 479px) {
  .contact-me-title {
    font-size: 8vw;
  }

  .contact-me-subtitle {
    font-size: 5vw;
  }

  .footer-end {
    flex-direction: column;
  }

  .footer-logo {
    padding: 30px 0;
    width: 50px;
  }

  .footer-end-left {
    width: 100%;
  }

  .footer-end-right-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-end-right {
    height: unset;
  }

  .footer-end-line {
    width: 100%;
  }

  .footer-end-right img {
    margin: 22px 12px;
    width: 35px;
    height: 35px;
  }
}
