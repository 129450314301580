.projects-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  min-height: 450px;
  border-radius: 15px;
  margin: 0 10px 60px 10px;
  box-shadow: 0 0 0 1px #000, 4px 4px 0 #000;
  transition: all 0.3s;
  position: relative;
}

.projects-span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  margin: 10px 15px;
}

.projects-card:hover {
  box-shadow: 0 0 0 1px #000, 0 0 0 #000;
}

.projects-card:nth-child(1) {
  background: rgba(249, 115, 22, 0.1);
}

.projects-card:nth-child(1) .projects-span {
  color: rgb(249, 115, 22);
}

.projects-card:nth-child(2) {
  background: rgba(45, 126, 248, 0.1);
}

.projects-card:nth-child(2) .projects-span {
  color: rgb(45, 126, 248);
}

.projects-card:nth-child(3) {
  background: rgba(0, 170, 69, 0.1);
}

.projects-card:nth-child(3) .projects-span {
  color: rgb(0, 170, 69);
}

.projects-card:nth-child(4) {
  background: rgba(241, 87, 87, 0.1);
}

.projects-card:nth-child(4) .projects-span {
  color: rgb(241, 87, 87);
}

.projects-card:nth-child(5) {
  background: rgba(127, 87, 241, 0.1);
}

.projects-card:nth-child(5) .projects-span {
  color: rgb(127, 87, 241);
}

.projects-card:nth-child(6) {
  background: rgba(0, 122, 255, 0.1);
}

.projects-card:nth-child(6) .projects-span {
  color: rgb(0, 122, 255);
}

.projects-card:nth-child(7) {
  background: rgba(242, 76, 76, 0.1);
}

.projects-card:nth-child(7) .projects-span {
  color: rgba(242, 76, 76, 1);
}

.projects-card:nth-child(8) {
  background: rgba(59, 172, 182, 0.1);
}

.projects-card:nth-child(8) .projects-span {
  color: rgba(59, 172, 182, 1);
}

.projects-card:nth-child(9) {
  background: rgba(243, 36, 36, 0.1);
}

.projects-card:nth-child(9) .projects-span {
  color: rgba(243, 36, 36, 1);
}

.projects-card:nth-child(10) {
  background: rgba(78, 148, 79, 0.1);
}

.projects-card:nth-child(10) .projects-span {
  color: rgba(78, 148, 79, 1);
}

.projects-card:nth-child(11) {
  background: rgba(54, 48, 98, 0.1);
}

.projects-card:nth-child(11) .projects-span {
  color: rgba(54, 48, 98, 1);
}

.projects-card:nth-child(12) {
  background: rgba(245, 109, 145, 0.1);
}

.projects-card:nth-child(12) .projects-span {
  color: rgba(245, 109, 145, 1);
}

.projects-card:nth-child(13) {
  background: rgba(0, 122, 255, 0.1);
}

.projects-card:nth-child(13) .projects-span {
  color: rgb(0, 122, 255);
}

.projects-card:nth-child(14) {
  background: rgba(77, 119, 255, 0.1);
}

.projects-card:nth-child(14) .projects-span {
  color: rgba(77, 119, 255, 1);
}

.projects-card:nth-child(15) {
  background: rgba(0, 43, 91, 0.1);
}

.projects-card:nth-child(15) .projects-span {
  color: rgba(0, 43, 91, 1);
}

.projects-card:nth-child(16) {
  background: rgba(185, 49, 96, 0.1);
}

.projects-card:nth-child(16) .projects-span {
  color: rgba(185, 49, 96, 1);
}

.projects-card:nth-child(17) {
  background: rgba(232, 170, 66, 0.1);
}

.projects-card:nth-child(17) .projects-span {
  color: rgba(232, 170, 66, 1);
}

.projects-card:nth-child(18) {
  background: rgba(26, 77, 46, 0.1);
}

.projects-card:nth-child(18) .projects-span {
  color: rgba(26, 77, 46, 1);
}

.projects-card:nth-child(19) {
  background: rgba(97, 12, 99, 0.1);
}

.projects-card:nth-child(19) .projects-span {
  color: rgba(97, 12, 99, 1);
}

.projects-p {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter";
  margin: 0 15px;
}

.projects-p2 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  margin: 5px 15px;
}

.projects-img {
  border-radius: 15px;
  background-size: cover;
  margin: 15px;
  height: 280px;
  object-position: center !important;
  transition: 0.3s;
}

.project-link-overlay {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 4px;
  color: #fefefe;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  left: 50%;
  opacity: 1;
  padding: 7px 20px;
  position: absolute;
  text-decoration: none;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
  visibility: hidden;
}

.project-link-overlay-2 {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 4px;
  color: #fefefe;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  left: 50%;
  opacity: 1;
  padding: 7px 20px;
  position: absolute;
  text-decoration: none;
  top: 75%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
  visibility: hidden;
}

.projects-card:hover .project-link-overlay {
  opacity: 1;
  visibility: visible;
}

.projects-card:hover .project-link-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.projects-card:hover .projects-img {
  filter: brightness(0.6);
}

.projects-card:hover .projects-card {
  filter: brightness(1.2);
}

@media screen and (max-width: 479px) {
  .projects-card {
    width: 350px;
  }
}

@media screen and (max-width: 380px) {
  .projects-card {
    width: 310px;
  }
  .projects-img {
    height: 270px;
  }
}
