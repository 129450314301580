@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
html,
body,
* {
  /* cursor: none; */
}

html,
body {
  height: 0;
}
body {
  background: url(/static/media/line-background-1.6e945f42.svg);
  background-color: rgb(251, 250, 255);
  background-size: cover;
}

@media screen and (max-width: 991px) {
  body {
    background: url(/static/media/line-background-991.ce42e3b7.svg);
    background-color: rgb(251, 250, 255);
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  body {
    background: url(/static/media/line-background-767.10842ef5.svg);
    background-color: rgb(251, 250, 255);
    background-size: cover;
  }
}

@media screen and (max-width: 479px) {
  body {
    background: url(/static/media/line-background-479.1f14331f.svg);
    background-color: rgb(251, 250, 255);
    background-size: cover;
  }
}

.App {
  margin: 0;
  padding: 0;
}
.noise::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  content: "";
  /* opacity: 1; */
  opacity: 0.025;
  z-index: 1500;
  pointer-events: none;
  background: url(/static/media/noise.93fe26ae.gif);
}
::-webkit-scrollbar {
  display: none;
}

.cursor-follower {
  position: absolute;
  background: url(/static/media/Red_asterisk.68f436c5.svg) no-repeat 50% 50%;
  background-size: cover;
  height: 50px;
  width: 50px;
  -webkit-animation: animate-rotate 12s infinite linear;
          animation: animate-rotate 12s infinite linear;
  z-index: 1500;
  -webkit-user-select: none;
          user-select: none;
  opacity: 0.8;
  mix-blend-mode: multiply;
  overflow: hidden;
  pointer-events: none;
  -webkit-transform: translate(35px, 35px);
          transform: translate(35px, 35px);
}
@-webkit-keyframes animate-rotate {
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes animate-rotate {
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .cursor-follower {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  iframe {
    bottom: 50px !important;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.loader-container svg {
  width: 400px !important;
}

@media screen and (max-width: 479px) {
  .loader-container svg {
    width: 200px !important;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px auto;
  height: 70px;
  z-index: 1000;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

header.nav-bar--hidden {
  -webkit-transform: translateY(-120%);
          transform: translateY(-120%);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 5vw;
}

#logo img {
  width: 40px;
  cursor: pointer;
}

.button img {
  width: 40px;
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.menu .button img {
  position: absolute;
  top: 50px;
  right: 100px;
  width: 25px;
  cursor: pointer;
  z-index: 10000;
}

.close {
  z-index: 1000;
  position: absolute;
  right: -20px;
}
.ul {
  position: absolute;
  left: 20%;
  display: flex;
  width: 300px;
  justify-content: center;
  align-items: left;
  height: 100vh;
  flex-direction: column;
}
.ul li {
  margin: 20px 0;
  z-index: 2;
}
.ul li p {
  text-decoration: none;
  color: white;
  transition: 2s;
  font-family: "MonumentExtended-Regular";
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 500;
  mix-blend-mode: difference;
  cursor: pointer;
}
.social {
  position: absolute;
  bottom: 0;
  display: flex;
  height: 80px;
  width: 100%;
}
.social ul {
  margin-right: 80px;
  display: flex;
  list-style: none;
  position: absolute;
  right: 0;
}
.social ul li {
  margin: 0px 10px;
}
.social ul li img {
  cursor: pointer;
  width: 35px;
  height: 35px;
}
.bg {
  position: absolute;
  width: 250px;
  /* filter: grayscale(1); */
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
  height: 300px;
  mix-blend-mode: difference;
}
.bg1 {
  top: 45px;
  left: 80px;
  background: url(/static/media/gif1.1b633ceb.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.bg2 {
  top: 100px;
  left: 150px;
  background: url(/static/media/gif2.5aef89ce.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.bg3 {
  top: 250px;
  left: 200px;
  background: url(/static/media/gif3.26367353.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.bg4 {
  top: 400px;
  left: 200px;
  background: url(/static/media/gif4.b049a861.gif) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: 1s ease-in-out;
}
.li1:hover ~ .bg1,
.li2:hover ~ .bg2,
.li3:hover ~ .bg3,
.li4:hover ~ .bg4 {
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: 1s ease-in-out;
}
.ul li p:hover {
  transition: 2s;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px white;
  font-style: italic;
}

@media screen and (max-width: 767px) {
  .ul li p {
    font-size: 35px;
  }
}

@media screen and (max-width: 479px) {
  #logo img {
    width: 30px;
  }
  .button img {
    width: 30px;
  }
  .ul {
    left: unset;
    bottom: 50px;
  }

  .ul li p {
    font-size: 30px;
  }

  .social {
    margin-bottom: 70px;
  }

  .social ul {
    right: unset;
    margin-right: unset;
  }
  .menu .button img {
    right: 55px;
    width: 20px;
  }
}

.works {
  text-align: center;
  font-weight: 400;
  font-family: "MonumentExtended-Regular";
  font-size: 80px;
  text-transform: uppercase;
  margin-top: 100px;
}

.project-page a {
  color: #642420;
  text-decoration: none;
}
.project-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 40px;
}
.project-page-items {
  display: flex;
  justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.project-page-item {
  padding: 20px 15px;
  margin: 20px 20px 0 20px;
  width: 380px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #000, 4px 4px 0 #000;
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}
.project-page-item:hover {
  box-shadow: 0 0 0 1px #000, 0 0 0 #000;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.project-page-item:nth-child(1) {
  background-color: #eaf2fe;
}
.project-page-item:nth-child(2) {
  background-color: #e5f6ec;
}
.project-page-item:nth-child(3) {
  background-color: #fef1e7;
}

.project-page-item-header {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  text-decoration: none;
}
.project-page-item-header h1 {
  font-size: 15px;
  font-weight: bold;
  width: 50%;
  color: #000;
  font-family: "Inter";
  text-decoration: none;
  text-transform: uppercase;
}
.project-page-item-header p {
  font-size: 10px;
  font-weight: 500;
  font-family: "Inter";
  width: 50%;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
}
.project-page-item-image {
  width: 100%;
  height: 400px;
  background-color: #642420;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
  transition: all 0.3s;
  border-radius: 10px;
}

.project-page-item-image canvas {
  border-radius: 10px;
}

.project-page-item-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  padding: 10px 0px;
}
.project-page-item-footer p {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  font-family: "Inter";
  text-transform: uppercase;
  text-decoration: none;
}
.project-page-item-footer img {
  width: 12px;
  margin-right: 5px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.view-all {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
}

.view-all a {
  padding: 10px 30px;
  /* color: rgb(0, 133, 181) !important; */
  color: #000;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 10px;
  transition: all 0.3s;
}

.view-all a span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-all-svg {
  padding-left: 15px;
  transition: all 0.3s;
}

.view-all a:hover {
  background: rgba(189, 24, 35, 0.1);
}

.view-all a:hover .view-all-svg {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}
@media screen and (max-width: 991px) {
  .works {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .works {
    font-size: 50px;
  }

  .view-all a {
    font-size: 13px;
  }
}

@media screen and (max-width: 479px) {
  .works {
    font-size: 12vw;
  }

  .view-all p {
    font-size: 15px;
  }

  .my-projects {
    height: 30vh;
    margin-top: 100px;
  }
  .my-projects h1 {
    font-size: 80px;
  }

  .project-page-item {
    height: 460px;
  }
}

#HiThere {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  pointer-events: none;
}

#HiThere h1 {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(30px * -1);
  white-space: nowrap;
  -webkit-user-select: none;
          user-select: none;
  margin-top: -20px;
  font-family: "MonumentExtended-Regular";
  font-size: 23.3vw;
  font-weight: 400;
  color: rgba(147, 147, 147, 0.23);
  line-height: 26vw;
}

.main-wrapper {
  margin-top: 25vh;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main-heading-div {
  display: flex;
}

.main-heading {
  font-family: "MonumentExtended-Regular";
  font-size: 10vw;
  font-weight: 400;
  z-index: 10;
  background-image: linear-gradient(89.2deg, #f58342 0.08%, #f5326d 90.89%);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;

  background-size: 300%;
  background-position: -100%;
  animation: textAnimate 3s infinite alternate-reverse;

  /* text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.1),
      0px -4px 10px rgba(255, 255, 255, 0.1); */
}

@-webkit-keyframes textAnimate {
  from {
    background-position: 0%;
  }

  to {
    background-position: 100%;
  }
}

@keyframes textAnimate {
  from {
    background-position: 0%;
  }

  to {
    background-position: 100%;
  }
}

.main-heading::before {
  content: attr(data-outline);
  position: absolute;
  left: 2%;
  top: -20%;
  width: 100%;
  text-align: center;
  font-family: "MonumentExtended-Regular";
  letter-spacing: 0.2rem;
  color: transparent;
  /* -webkit-text-stroke: 1.5px rgba(0, 0, 0, 0.103); */
  -webkit-text-stroke: 1.5px #edeef71a;
  z-index: 0;
}

.main-heading:nth-child(1) {
  padding-right: 1.5vw;
}

.main-heading:nth-child(2) {
  padding-left: 1.5vw;
}

.top-main-heading {
  font-family: "Inter";
  font-size: 2.65vw;
  font-weight: 900;
  z-index: 10;
  margin-bottom: 40px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1.5px #000;
}

.bottom-main-heading {
  font-family: "Inter";
  height: 50px;
  font-size: 2.65vw;
  font-weight: 700;
  z-index: 10;
  margin-top: 40px;
}

.scroll-down {
  width: 180px;
  margin-top: 70px;
}

.flower {
  position: absolute;
  bottom: 100px;
  right: 100px;
  width: 80px;
  -webkit-animation: animate-rotate 10s infinite linear;
          animation: animate-rotate 10s infinite linear;
}

@-webkit-keyframes animate-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes animate-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*  */
@media screen and (max-width: 991px) {
  #HiThere h1 {
    top: unset;
  }
}

@media screen and (max-width: 767px) {
  #HiThere h1 {
    margin-top: 88px;
    font-size: 24vw;
    line-height: 30vw;
  }
  .main-wrapper {
    margin-top: 26vw;
  }
  .scroll-down {
    width: 130px;
    margin-top: 111px;
  }
}

@media screen and (max-width: 479px) {
  .main-wrapper {
    margin-top: 35vw;
  }
  .main-heading-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .main-heading:nth-child(1) {
    padding-right: unset;
  }
  .main-heading:nth-child(2) {
    padding-left: unset;
  }
  .main-heading {
    font-size: 18vw;
  }
  .top-main-heading {
    font-size: 6vw;
  }
  .bottom-main-heading {
    font-size: 5.5vw;
    margin-top: 65px;
  }
  #HiThere h1 {
    top: -15px;
    left: 20px;
    line-height: 60vw;
  }
  .scroll-down {
    width: 140px;
    margin-top: unset;
  }
  .flower {
    bottom: 125px;
    width: 40px;
  }
}

.short-about {
  margin-top: 8vh;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.main-h1-short-about {
  width: 100%;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-img-1 {
  width: 180px;
}

.bg-img-2 {
  width: 180px;
}

.sub-main-p-short-about {
  margin-top: 80px;
}

.short-about h1 {
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  margin-bottom: -30px;
  letter-spacing: -2px;
  text-align: center;
}
.short-about p {
  text-align: center;
  color: #642420;
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}

.know-more-resume {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
}
.know-more-about {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#know-more {
  padding-top: 30px;
  color: rgb(0, 133, 181) !important;
  cursor: pointer;
  font-family: "Inter";
}
.know-more-about img {
  width: 20px;
  /* transform: rotate(270deg); */
  color: rgb(0, 133, 181) !important;
  padding-top: 50px;
  padding-left: 20px;
  cursor: pointer;
}
.another-svg {
  margin-top: 50px;
}
.short-about .another-svg img {
  width: 50px;
  -webkit-animation: animate-rotate 10s infinite linear;
          animation: animate-rotate 10s infinite linear;
}

@-webkit-keyframes animate-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes animate-rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 991px) {
  .short-about h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .short-about h1 {
    font-size: 50px;
    margin-bottom: -18px;
  }

  .bg-img-1,
  .bg-img-2 {
    width: 130px;
  }

  .short-about p {
    font-size: 15px;
  }

  .about-btn a {
    font-size: 13px;
  }

  .short-about .another-svg img {
    width: 40px;
  }
}

@media screen and (max-width: 479px) {
  .short-about {
    /* margin-top: 10vh; */
  }

  .main-h1-short-about {
    margin-top: unset;
    justify-content: space-around;
  }

  .bg-img-1 {
    width: 80px;
    margin-left: unset;
  }

  .bg-img-2 {
    width: 80px;
    margin-right: unset;
  }

  .short-about h1 {
    font-size: 10vw;
    margin-bottom: -18px;
  }

  .sub-main-p-short-about {
    max-width: 300px;
  }

  .short-about p {
    font-size: 15px;
  }

  .know-more-resume {
    padding: unset;
    flex-direction: column;
  }

  .short-about .another-svg img {
    width: 40px;
  }
}

/*  */

.about-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}
.about-btn a {
  padding: 10px 30px;
  /* color: rgb(0, 133, 181) !important; */
  color: #000;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 10px;
  transition: all 0.3s;
}

.about-btn a span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-btn-svg {
  padding-left: 15px;
  transition: all 0.3s;
}

.about-btn a:hover {
  background: rgba(189, 24, 35, 0.1);
}

.about-btn a:hover .about-btn-svg {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}

.my-skills-main-reel {
  width: 100%;
  overflow: hidden;
  margin-top: -140px;
}

.my-skills-main-reel p {
  text-align: center;
  color: #642420;
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}

.my-skills-reel {
  margin-top: 300px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  will-change: transform;
  -webkit-animation: animation-reel 120s infinite linear;
          animation: animation-reel 120s infinite linear;
}

@-webkit-keyframes animation-reel {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes animation-reel {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.reel-item {
  font-weight: 400;
  font-family: "MonumentExtended-Regular";
  font-size: 80px;
  text-transform: uppercase;
}
/*  */

.skill-set-boxes {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 100px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  flex-wrap: wrap;
}

.skill-set-box {
  margin: 50px 10px;
  display: flex;
  width: 400px;
  height: 180px;
  flex-direction: column;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='black' stroke-width='1' stroke-dasharray='16%2c 7%2c 18%2c 12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  /* border: 1px dashed #000;
    border-radius: 20px; */
  transition: color 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  transition: background-color 1s cubic-bezier(0.16, 1, 0.3, 1);
  transition: border 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}

.skill-set-box:hover {
  color: #fff;
  background-color: #101010;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='#ffffff' stroke-width='1' stroke-dasharray='16%2c 7%2c 18%2c 12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
}

.skill-set-box:hover .skill-set-box-p {
  color: #fff;
}

.skill-set-box:hover .skill-line {
  color: #fff;
}

.skill-set-box-main {
  display: flex;
  margin: 30px;
}

.skill-set-box-main-img {
  width: 40px;
}

.skill-set-box-h1 {
  font-size: 35px;
  font-family: "MonumentExtended-Regular";
  font-weight: 500;
  margin-left: 25px;
}

.skill-set-box-p {
  text-align: center;
  color: #642420;
  font-family: "Inter";
  font-weight: lighter;
  font-size: 15px;
  /* margin: 0 30px; */
}

.skill-line {
  margin: 0 18px;
  font-size: 16px;
  font-weight: 900;
  color: #000;
}

@media screen and (max-width: 991px) {
  .reel-item {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .reel-item {
    font-size: 50px;
  }
  .my-skills-main-reel p {
    font-size: 15px;
  }
  .skill-set-box-h1 {
    font-size: 25px;
  }
  .skill-set-box-p {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) {
  .reel-item {
    font-size: 12vw;
  }

  .my-skills-reel {
    margin-top: 230px;
  }

  .my-skills-main-reel p {
    font-size: 15px;
  }

  .skill-set-boxes {
    margin-top: 50px;
  }

  .skill-set-box {
    margin: 25px 10px;
    height: 150px;
  }

  .skill-set-box-h1 {
    font-size: 25px;
  }

  .skill-set-box-main-img {
    width: 30px;
  }
}

.contact-me {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.contact-me-wrapper {
  width: 90%;
}

.contact-me-title {
  font-size: 60px;
  font-family: "MonumentExtended-Regular";
  font-weight: 400;
}

.contact-me-subtitle {
  font-size: 40px;
  font-family: "MonumentGrotesk-Regular";
  font-weight: bold;
}

.footer-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 150px;
}

.footer-logo {
  width: 70px;
}

.footer-end-line {
  display: block;
  width: 500px;
  border-top: 1px solid black;
}

.email,
.phone {
  font-size: 20px;
  font-family: "MonumentGrotesk-Bold";
  text-decoration: none;
  color: black;
}

.email-link,
.phone-link {
  text-decoration: none;
}

.footer-end-left {
  height: 125px;
}

.footer-end-right {
  display: flex;
  height: 125px;
}

.footer-end-right img {
  width: 40px;
  height: 40px;
  margin: 30px 18px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .contact-me-title {
    font-size: 50px;
  }
  .contact-me-subtitle {
    font-size: 30px;
  }
  .footer-end-line {
    width: 333px;
  }
  .footer-end-right img {
    margin: 30px 8px;
  }
}

@media screen and (max-width: 767px) {
  .contact-me-title {
    font-size: 40px;
  }
  .contact-me-subtitle {
    font-size: 25px;
  }
  .footer-end {
    flex-direction: column;
    text-align: center;
  }
  .footer-logo {
    margin: 40px 0;
  }
  .footer-end-right {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 479px) {
  .contact-me-title {
    font-size: 8vw;
  }

  .contact-me-subtitle {
    font-size: 5vw;
  }

  .footer-end {
    flex-direction: column;
  }

  .footer-logo {
    padding: 30px 0;
    width: 50px;
  }

  .footer-end-left {
    width: 100%;
  }

  .footer-end-right-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-end-right {
    height: unset;
  }

  .footer-end-line {
    width: 100%;
  }

  .footer-end-right img {
    margin: 22px 12px;
    width: 35px;
    height: 35px;
  }
}

.about-section {
  margin-top: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-wrapper {
  width: 80%;
}

.about-section-heading {
  text-align: center;
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  letter-spacing: -2px;
  margin-bottom: 80px;
}

.about-wrapper2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.about-left {
  flex: 1 1;
}

.about-img {
  width: 450px;
}

.about-right {
  flex: 1 1;
  margin-top: 20px;
  margin-left: -50px;
}

.about-short-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "Caveat";
  color: #bd1823;
}

.about-heading {
  font-size: 40px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  letter-spacing: -2px;
  margin-bottom: 30px;
  background-image: linear-gradient(to right, #bd1823, #bd1823);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 20% 2px;
}

.about-span {
  color: black;
  font-family: "Inter";
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 1.5;
}

.certifications {
  margin-top: 80px;
}

.about-heading2 {
  font-size: 40px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  margin-bottom: 30px;
  background-image: linear-gradient(to right, #bd1823, #bd1823);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 20% 2px;
}

.certificates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
}

.certificate-img {
  width: 580px;
  height: 380px;
  margin-bottom: 50px;
  border: 2px solid #bd1823;
}

.overlay {
  position: absolute;
  bottom: 55px;
  /* background: rgb(0, 0, 0); */
  background: rgba(0, 0, 0, 0.6); /* Black see-through */
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  font-family: "Inter";
  padding: 20px 0;
  text-align: center;
}

.image-container:hover .overlay {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .about-section-heading {
    font-size: 70px;
  }
  .about-img {
    width: 350px;
  }
  .about-short-heading {
    font-size: 20px;
  }
  .about-heading {
    font-size: 35px;
  }
  .about-span {
    font-size: 16px;
  }
  .certificates {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .about-section-heading {
    font-size: 50px;
  }
  .about-img {
    width: 250px;
  }
  .about-short-heading {
    font-size: 18px;
  }
  .about-heading {
    font-size: 35px;
  }
  .about-span {
    font-size: 15px;
  }
  .certificates {
    justify-content: center;
  }
}

@media screen and (max-width: 479px) {
  .about-section {
    margin-top: 100px;
    margin-bottom: -40px;
  }

  .about-section-heading {
    font-size: 10vw;
  }

  .about-wrapper2 {
    flex-direction: column;
  }

  .about-img {
    width: 360px;
  }

  .about-heading,
  .about-heading2 {
    font-size: 30px;
  }

  .about-span {
    font-size: 15px;
  }

  .about-right {
    margin-left: unset;
    margin-top: 65px;
  }

  .certificates {
    flex-direction: column;
  }

  .certificate-img {
    width: 330px;
    height: 220px;
  }
}

@media screen and (max-width: 380px) {
  .certificate-img {
    width: 288px;
    height: 200px;
  }
}

.project-section {
  margin-top: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-wrapper {
  width: 90%;
}

.project-section-heading {
  text-align: left;
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  letter-spacing: -2px;
  margin-bottom: 80px;
  background-image: linear-gradient(to right, #000, #000);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 2px;
}

.projects {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .project-section-heading {
    font-size: 70px;
  }
  .projects-card {
    margin: 0 25px 60px 25px;
  }
}

@media screen and (max-width: 767px) {
  .project-section-heading {
    font-size: 50px;
  }
}

@media screen and (max-width: 479px) {
  .project-section-heading {
    font-size: 10vw;
  }
}

.projects-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  min-height: 450px;
  border-radius: 15px;
  margin: 0 10px 60px 10px;
  box-shadow: 0 0 0 1px #000, 4px 4px 0 #000;
  transition: all 0.3s;
  position: relative;
}

.projects-span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  margin: 10px 15px;
}

.projects-card:hover {
  box-shadow: 0 0 0 1px #000, 0 0 0 #000;
}

.projects-card:nth-child(1) {
  background: rgba(249, 115, 22, 0.1);
}

.projects-card:nth-child(1) .projects-span {
  color: rgb(249, 115, 22);
}

.projects-card:nth-child(2) {
  background: rgba(45, 126, 248, 0.1);
}

.projects-card:nth-child(2) .projects-span {
  color: rgb(45, 126, 248);
}

.projects-card:nth-child(3) {
  background: rgba(0, 170, 69, 0.1);
}

.projects-card:nth-child(3) .projects-span {
  color: rgb(0, 170, 69);
}

.projects-card:nth-child(4) {
  background: rgba(241, 87, 87, 0.1);
}

.projects-card:nth-child(4) .projects-span {
  color: rgb(241, 87, 87);
}

.projects-card:nth-child(5) {
  background: rgba(127, 87, 241, 0.1);
}

.projects-card:nth-child(5) .projects-span {
  color: rgb(127, 87, 241);
}

.projects-card:nth-child(6) {
  background: rgba(0, 122, 255, 0.1);
}

.projects-card:nth-child(6) .projects-span {
  color: rgb(0, 122, 255);
}

.projects-card:nth-child(7) {
  background: rgba(242, 76, 76, 0.1);
}

.projects-card:nth-child(7) .projects-span {
  color: rgba(242, 76, 76, 1);
}

.projects-card:nth-child(8) {
  background: rgba(59, 172, 182, 0.1);
}

.projects-card:nth-child(8) .projects-span {
  color: rgba(59, 172, 182, 1);
}

.projects-card:nth-child(9) {
  background: rgba(243, 36, 36, 0.1);
}

.projects-card:nth-child(9) .projects-span {
  color: rgba(243, 36, 36, 1);
}

.projects-card:nth-child(10) {
  background: rgba(78, 148, 79, 0.1);
}

.projects-card:nth-child(10) .projects-span {
  color: rgba(78, 148, 79, 1);
}

.projects-card:nth-child(11) {
  background: rgba(54, 48, 98, 0.1);
}

.projects-card:nth-child(11) .projects-span {
  color: rgba(54, 48, 98, 1);
}

.projects-card:nth-child(12) {
  background: rgba(245, 109, 145, 0.1);
}

.projects-card:nth-child(12) .projects-span {
  color: rgba(245, 109, 145, 1);
}

.projects-card:nth-child(13) {
  background: rgba(0, 122, 255, 0.1);
}

.projects-card:nth-child(13) .projects-span {
  color: rgb(0, 122, 255);
}

.projects-card:nth-child(14) {
  background: rgba(77, 119, 255, 0.1);
}

.projects-card:nth-child(14) .projects-span {
  color: rgba(77, 119, 255, 1);
}

.projects-card:nth-child(15) {
  background: rgba(0, 43, 91, 0.1);
}

.projects-card:nth-child(15) .projects-span {
  color: rgba(0, 43, 91, 1);
}

.projects-card:nth-child(16) {
  background: rgba(185, 49, 96, 0.1);
}

.projects-card:nth-child(16) .projects-span {
  color: rgba(185, 49, 96, 1);
}

.projects-card:nth-child(17) {
  background: rgba(232, 170, 66, 0.1);
}

.projects-card:nth-child(17) .projects-span {
  color: rgba(232, 170, 66, 1);
}

.projects-card:nth-child(18) {
  background: rgba(26, 77, 46, 0.1);
}

.projects-card:nth-child(18) .projects-span {
  color: rgba(26, 77, 46, 1);
}

.projects-card:nth-child(19) {
  background: rgba(97, 12, 99, 0.1);
}

.projects-card:nth-child(19) .projects-span {
  color: rgba(97, 12, 99, 1);
}

.projects-p {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter";
  margin: 0 15px;
}

.projects-p2 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  margin: 5px 15px;
}

.projects-img {
  border-radius: 15px;
  background-size: cover;
  margin: 15px;
  height: 280px;
  object-position: center !important;
  transition: 0.3s;
}

.project-link-overlay {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 4px;
  color: #fefefe;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  left: 50%;
  opacity: 1;
  padding: 7px 20px;
  position: absolute;
  text-decoration: none;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
  visibility: hidden;
}

.project-link-overlay-2 {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  border-radius: 4px;
  color: #fefefe;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  left: 50%;
  opacity: 1;
  padding: 7px 20px;
  position: absolute;
  text-decoration: none;
  top: 75%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
  visibility: hidden;
}

.projects-card:hover .project-link-overlay {
  opacity: 1;
  visibility: visible;
}

.projects-card:hover .project-link-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.projects-card:hover .projects-img {
  -webkit-filter: brightness(0.6);
          filter: brightness(0.6);
}

.projects-card:hover .projects-card {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

@media screen and (max-width: 479px) {
  .projects-card {
    width: 350px;
  }
}

@media screen and (max-width: 380px) {
  .projects-card {
    width: 310px;
  }
  .projects-img {
    height: 270px;
  }
}

.contact-section {
  margin-top: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-wrapper {
  width: 80%;
}

.section-heading {
  text-align: center;
  font-size: 80px;
  font-weight: 500;
  font-family: "MonumentExtended-Regular";
  margin-bottom: -30px;
  letter-spacing: -2px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 5rem;
  gap: 5rem;
  margin-top: 100px;
}

.contact-left {
  flex: 1 1;
}

.contact-heading {
  font-family: "Inter";
  color: black;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.contact-desc {
  color: black;
  font-family: "Inter";
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 2;
}

.contact-info {
  margin-bottom: 2rem;
}

.icon {
  color: black;
  font-size: 25px;
}

.text {
  color: black;
  font-size: 20px;
  font-family: "Inter";
  text-decoration: none;
}

.contact-info ul {
  list-style: none;
  padding-left: 0;
}

.contact-info ul li {
  border-radius: 12px;
  overflow: hidden;
}

.contact-info ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 2rem;
  padding: 1.5rem;
  text-decoration: none;
}

.social-info ul {
  display: flex;
  list-style: none;
  -webkit-padding-start: unset;
          padding-inline-start: unset;
  grid-gap: 2rem;
  gap: 2rem;
}

.brands {
  font-size: 2.5rem;
}

.social-info ul li a {
  color: black;
}

.contact-right {
  flex: 1 1;
}

.form-wrapper {
  background: rgba(249, 115, 22, 0.1);
  max-width: 500px;
  margin: 2px auto;
  padding: 5rem;
  border-radius: 12px;
  color: black;
  box-shadow: 0 0 0 1px #000, 4px 4px 0 #000;
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='black' stroke-width='1' stroke-dasharray='16%2c 7%2c 18%2c 12' stroke-dashoffset='19' stroke-linecap='square'/%3e%3c/svg%3e"); */
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: 0.5rem;
  display: inline-block;
  font-size: 20px;
  font-family: "Inter";
}

.input-wrapper {
  background-color: white;
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
}

.input-wrapper .icons {
  font-size: 20px;
  height: 100%;
  width: 50px;
  background-color: rgb(218, 218, 218);
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-wrapper input {
  padding: 1rem;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  font-size: 2rem;
}

.form-group textarea {
  width: 92%;
  height: 250px;
  resize: vertical;
  font-size: 2rem;
  padding: 1rem;
  outline: none;
  border: none;
  border-radius: 4px;
}

.form-wrapper button {
  background-image: linear-gradient(
    to right,
    #232526 0%,
    #414345 51%,
    #232526 100%
  );

  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  outline: none;
  border: none;
}

.form-wrapper button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  .section-heading {
    font-size: 70px;
  }
  .wrapper {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .form-wrapper {
    padding: 2.5rem;
  }
}

@media screen and (max-width: 767px) {
  .section-heading {
    font-size: 50px;
  }
  .wrapper {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 479px) {
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-section {
    margin-top: 100px;
  }

  .wrapper {
    flex-direction: column-reverse;
  }

  .section-heading {
    font-size: 10vw;
    margin-bottom: -50px;
  }

  .form-wrapper {
    padding: 2rem;
  }

  .form-group textarea {
    width: 89%;
  }

  .contact-heading {
    font-size: 2rem;
  }

  .contact-desc {
    font-size: 16px;
    text-align: center;
  }

  .text {
    font-size: 16px;
    font-weight: 500;
  }

  .icon {
    font-size: 18px;
  }

  .social-info ul {
    grid-gap: 1.2rem;
    gap: 1.2rem;
  }

  .contact-heading {
    text-align: center;
  }
}

.resume {
  width: 100%;
}

.routing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.routing-main {
  width: 80%;
  display: flex;
  align-items: center;
  margin-top: 60px;
  justify-content: space-between;
}

.routing-links {
  margin-bottom: -30px;
  font-size: 20px;
  font-family: "MonumentExtended-Regular";
  font-weight: 400;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.routing-links:hover {
  color: brown;
}

.resume-link {
  -webkit-text-decoration: "none";
          text-decoration: "none";
  margin-top: "70px";
}

@media screen and (max-width: 479px) {
  .routing-main {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: -40px;
  }

  .resume-link {
    margin-bottom: 30px;
  }
}

@font-face {
  font-family: "MonumentExtended-Regular";
  src: local("MonumentExtended-Regular"),
    url(/static/media/MonumentExtended-Regular.db74f7a5.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "MonumentExtended-Ultrabold";
  src: local("MonumentExtended-Ultrabold"),
    url(/static/media/MonumentExtended-Ultrabold.9db16afe.otf) format("opentype");
}

@font-face {
  font-family: "MonumentGrotesk-Bold";
  src: local("MonumentGrotesk-Bold"),
    url(/static/media/MonumentGrotesk-Bold.62d848cf.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-BoldItalic";
  src: local("MonumentGrotesk-BoldItalic"),
    url(/static/media/MonumentGrotesk-BoldItalic.7242b7af.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Italic";
  src: local("MonumentGrotesk-Italic"),
    url(/static/media/MonumentGrotesk-Italic.38e3e6f7.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Medium";
  src: local("MonumentGrotesk-Medium"),
    url(/static/media/MonumentGrotesk-Medium.e42b815e.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-MediumItalic";
  src: local("MonumentGrotesk-MediumItalic"),
    url(/static/media/MonumentGrotesk-MediumItalic.ea8c65db.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Mono";
  src: local("MonumentGrotesk-Mono"),
    url(/static/media/MonumentGrotesk-Mono.5fa7c990.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Regular";
  src: local("MonumentGrotesk-Regular"),
    url(/static/media/MonumentGrotesk-Regular.02c608d4.ttf) format("truetype");
}

@font-face {
  font-family: "MonumentGrotesk-Semi-Mono";
  src: local("MonumentGrotesk-Semi-Mono"),
    url(/static/media/MonumentGrotesk-Semi-Mono.71a20188.ttf) format("truetype");
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"), url(/static/media/Caveat.026e363f.ttf) format("truetype");
}

@font-face {
  font-family: "Ogg";
  src: url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.eot");
  src: url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/2596224269750e00c3ad5356299a3b9f.svg#Ogg")
      format("svg");
}

