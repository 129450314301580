.works {
  text-align: center;
  font-weight: 400;
  font-family: "MonumentExtended-Regular";
  font-size: 80px;
  text-transform: uppercase;
  margin-top: 100px;
}

.project-page a {
  color: #642420;
  text-decoration: none;
}
.project-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin-top: 40px;
}
.project-page-items {
  display: flex;
  justify-content: center;
  height: fit-content;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.project-page-item {
  padding: 20px 15px;
  margin: 20px 20px 0 20px;
  width: 380px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #000, 4px 4px 0 #000;
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}
.project-page-item:hover {
  box-shadow: 0 0 0 1px #000, 0 0 0 #000;
  transform: scale(1.05);
}
.project-page-item:nth-child(1) {
  background-color: #eaf2fe;
}
.project-page-item:nth-child(2) {
  background-color: #e5f6ec;
}
.project-page-item:nth-child(3) {
  background-color: #fef1e7;
}

.project-page-item-header {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  text-decoration: none;
}
.project-page-item-header h1 {
  font-size: 15px;
  font-weight: bold;
  width: 50%;
  color: #000;
  font-family: "Inter";
  text-decoration: none;
  text-transform: uppercase;
}
.project-page-item-header p {
  font-size: 10px;
  font-weight: 500;
  font-family: "Inter";
  width: 50%;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
}
.project-page-item-image {
  width: 100%;
  height: 400px;
  background-color: #642420;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
  transition: all 0.3s;
  border-radius: 10px;
}

.project-page-item-image canvas {
  border-radius: 10px;
}

.project-page-item-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
  padding: 10px 0px;
}
.project-page-item-footer p {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  font-family: "Inter";
  text-transform: uppercase;
  text-decoration: none;
}
.project-page-item-footer img {
  width: 12px;
  margin-right: 5px;
  transform: rotate(270deg);
}

.view-all {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
}

.view-all a {
  padding: 10px 30px;
  /* color: rgb(0, 133, 181) !important; */
  color: #000;
  display: flex;
  align-items: center;
  /* cursor: pointer; */
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 10px;
  transition: all 0.3s;
}

.view-all a span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-all-svg {
  padding-left: 15px;
  transition: all 0.3s;
}

.view-all a:hover {
  background: rgba(189, 24, 35, 0.1);
}

.view-all a:hover .view-all-svg {
  transform: translateX(8px);
}
@media screen and (max-width: 991px) {
  .works {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .works {
    font-size: 50px;
  }

  .view-all a {
    font-size: 13px;
  }
}

@media screen and (max-width: 479px) {
  .works {
    font-size: 12vw;
  }

  .view-all p {
    font-size: 15px;
  }

  .my-projects {
    height: 30vh;
    margin-top: 100px;
  }
  .my-projects h1 {
    font-size: 80px;
  }

  .project-page-item {
    height: 460px;
  }
}
